import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useTheme, styled } from '@mui/material/styles';
import { sentenceCase } from 'change-case';
import React, { useState, useEffect, useRef  } from 'react';
import TextField from '@mui/material/TextField';
import './App.css';
// @mui
import { MapContainer, TileLayer, Circle, Popup, Polyline } from 'react-leaflet';

import {
  Card,
  Grid,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  FormControlLabel,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  ToggleButton,
  Typography,
  Fab,
  Dialog,
  DialogTitle,
  ToggleButtonGroup,
  IconButton,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import { decode } from '@mapbox/polyline';
import CircularProgress from '@mui/material/CircularProgress';

import Iconify from '../components/iconify';


import CONFIG from "../config";


const cardWrapperStyle = {
  position: 'relative',
  paddingBottom: '100%', /* This creates a 1:1 aspect ratio */
  
};

const cardStyle = {
  background: '#111',
  borderRadius: '50%',
  position: 'absolute',
  width: '100%',
  height: '100%',
};

const imgStyle = {
  height: '100%',
  width: '100%',
  objectFit: 'cover',
};
const labelStyle = {
  position: 'absolute',
  top: '5px',
  left: '30px',
  color: '#fff',
  fontSize: '16px',
  background: 'rgba(20, 20, 20, 0.8)',
  padding: '10px',
  backdropFilter: "blur(6px)",
  zIndex: 100,
};
const labelStyle2 = {
  position: 'absolute',
  bottom: '-10px',
  textAlign: 'center',
  left: '50%',
  transform: 'translate(-50%, -50%)', 
  color: '#fff',
  padding: '10px',
  fontSize: '16px',
  background: 'rgba(20, 20, 20, 0.8)',
  backdropFilter: "blur(6px)",
  zIndex: 100,
};

function ImageWithAuthorization({ circle }) {
  const imageRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const fetchImageData = async (url) => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Authorization failed');
      }

      const blob = await response.blob();
      setLoading(false)
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error('Error fetching image:', error);
      setLoading(false)
      return null;
    }
  };

  const loadImage = () => {
    fetchImageData(circle.path)
      .then(imageUrl => {
        if (imageUrl && imageRef.current) {
          imageRef.current.src = imageUrl;
        }
      })
      .catch(error => {
        console.error('Error fetching image:', error);
      });
  };

  return (
    <>
    <div style={{ position: 'relative', width: '300px', height: '300px' }}>
      {loading && (
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <CircularProgress color="primary" size={50} />
        </div>
      )}

      <img
        ref={imageRef}
        src={circle.path}
        alt={''}
        onError={loadImage}
        style={{ height: '300px', width: '300px', objectFit: 'cover', minWidth: '300px' }}
      />
    </div>
    </>
  );
}
export default function MapPage() {
  
  const Fav = styled('fab')(({ theme }) => ({
    position: 'fixed',
    zIndex: 500,
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  }));
  const [away, setAway] = useState([]);

  const [user, setUser] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [circles, setCircles] = useState([]);
  const [type, setType] = useState("images");

  useEffect(() => {  
    setIsMobile(window.innerWidth <= 900);
    
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
      return;
    }
    fetch(`${CONFIG.BACKEND_URL}/api/user`, {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` }
    }).then(response => {
      console.log(response)
      setUser(response.json().user);
    })
    .catch(error => {
      console.log(error);
      window.location.href = '/login';
    })
  }, []);
  async function postData(url, payload) {
    const token = localStorage.getItem('token');
    console.log(token)
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    return data;
  }
  
  async function fetchData(url) {
    const token = localStorage.getItem('token');
    console.log(token)
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    const data = await response.json();
    return data;    
  }
  const fetchSearch = async () => {
    try {
      const responseData = await postData(`${CONFIG.BACKEND_URL}/api/query`, { input: "", imageDays: 'images', columns: [], showFaces: false, limit: -1});
      return JSON.parse(responseData);
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };
  
  useEffect(() => {  
    Promise.all([
      fetchData(`${CONFIG.BACKEND_URL}/api/get_away`).then(( stance ) => {          
        const parsed = JSON.parse(stance);
        setAway(parsed);
      })
  ]).then(() => {
  });
}, []);
  
  useEffect(() => {  
    
    async function fetch() {
      let data = await fetchSearch()
      
      console.log(data)
      data = data.filter( (item) =>  item.gps !== null && item.gps !== undefined && item.gps.length > 0);
      data = data.map((item) => {
        // Split the "gps" property by comma and then convert each element to a float value
        const gpsArray = item.gps.split(', ').map((gpsValue) => parseFloat(gpsValue));
        
        // Update the "gps" property with the new float array
        return  {gps: gpsArray, date: item.date, path: `${CONFIG.BACKEND_URL}/api/images/${item.date}/compressed/${item.path.split("/").pop()}`};
      });
      //  data = data.filter((item) => item !== [0,0]);
      const circles = data.map((item) => {
        const frequency = 1;
        const radius = isMobile ? 200 : 50;
        return {
          position: item.gps,
          date: item.date,
          radius,
          path: item.path,
          frequency,
        };
      });
    
      console.log(data)
      setCircles(circles)
    }
    fetch();
  
  }, []);
  

  const [runs, setRuns] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [differentColors, setDifferentColors] = useState(false);
  const [mapType, setMapType] = useState("light");
  const [lineCols, setLineCols] = useState([]);
  const [dates, setDates] = useState([]);
  const [polylineStyle, setPolylineStyle] = useState([]);
  const [currRouteIndex, setCurrRouteIndex] = useState(0);
  const [displayType, setDisplayType] = useState("both");
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
 
  const handleCloseDialog = () => {
      setDialogOpen(false);
  }; 
  const handleMapType =(event, newAlignment) => {

    if (newAlignment !== null) 
    setMapType(newAlignment);
  
    const currFormatNormal = [];
    lineCols.forEach(() => {
      currFormatNormal.push({  color: (newAlignment === 'light') ? '#2065d1' : (newAlignment === 'normal') ? '#163446' : '#f79c70', width: 1 })  });
    
    setPolylineStyle(currFormatNormal)
  };
  const handleDisplayType =(event, newAlignment) => {
    if (newAlignment !== null) 
    setDisplayType(newAlignment);
  };
  
  function getRandomHexColor() {
    const minComponent = 64; // Minimum component value to avoid very bright colors
    const maxComponent = 180; // Maximum component value to keep the colors somewhat muted
    const letters = '0123456789ABCDEF';
    let color = '#';
  
    for (let i = 0; i < 6; i+=1) {
      const randomComponent = Math.floor(Math.random() * (maxComponent - minComponent + 1)) + minComponent;
      color += letters[randomComponent % 16];
    }
  
    return color;
  }

  function getRandomBlueShade() {
    const minShade = 100; // Minimum shade value to ensure visibility
    const maxShade = 255; // Maximum shade value for blue
    const randomBlue = Math.floor(Math.random() * (maxShade - minShade + 1)) + minShade;
    return `#${'00'}${'00'}${randomBlue.toString(16).padStart(2, '0')}`;
  }

  
  useEffect(() => {  
    console.log(currRouteIndex)
    //  if(polylineStyle.length > currRouteIndex)
    //  setPolylineStyle(polylineStyle[currRouteIndex].color= '#f00');
  }, [currRouteIndex]);

  const standardStyle = {  color: (mapType === 'dark') ? '#f79c70' : '#2065d1', width: 1 };
  useEffect(() => {  
      Promise.all([
        fetchData(`${CONFIG.BACKEND_URL}/api/runs`).then(( stance ) => {          
          const parsed = JSON.parse(stance);
          const polylines=parsed.map(obj => obj.summary_polyline );
          const tempDates = [];
          const ids=parsed.map(obj => obj.id )
          const coordinates = [];
          const currFormat = [];
          const currFormatNormal = [];
          polylines.forEach((encodedPolyline, index) => {
            if(ids[index] !== 8503053468 && ids[index] !== 8553464331){
            const latLngPoints = decode(encodedPolyline)
            const currCord = []
                  for(let i = 0; i < latLngPoints.length; i+=1)
                  currCord.push(latLngPoints[i]);
                  coordinates.push(currCord) 
                  tempDates.push({id: parsed[index].id, path: `${CONFIG.BACKEND_URL}/api/runs/${parsed[index].id}.png`, date: parsed[index].start_date_local, distance: (parsed[index].distance / 1000).toFixed(2)})
            }
            currFormat.push({  color:getRandomHexColor() })
            currFormatNormal.push(standardStyle)
          });
          setDates(tempDates)
          setLineCols(currFormat)
          setPolylineStyle(currFormatNormal)
          console.log(coordinates)
          console.log("lineCols")
          console.log(lineCols)
          setRuns(coordinates);
        })
    ]).then(() => {
    });
  }, []);

  const handleDiffColors= () => {
      const currFormat = [];
      lineCols.forEach(() => {
        currFormat.push({  color:getRandomHexColor() })
        currFormat.push({  color:getRandomHexColor() })  });
      
      setLineCols(currFormat)
      setDifferentColors(!differentColors);
  };
  const mapboxAccessToken = "pk.eyJ1IjoibGF3aXgxMCIsImEiOiJjamJlOGE1bmcyZ2V5MzNtcmlyaWRzcDZlIn0.ZRQ73zzVxwcADIPvsqB6mg"
  const handlePopupOpen = (index) => {
    console.log(index)
    setCurrRouteIndex(index);
  };

  const handlePopupClose = () => {
    console.log("Close")
    setCurrRouteIndex(null);
  };

  const imageRef = useRef(null);


  return (
    <>
    
    <Helmet>
        <title> Map | Blue </title>
      </Helmet>
    <Dialog  open={dialogOpen} onClose={handleCloseDialog} >
      <DialogTitle style={{width: '100%', background: '#000'}}>Map-Optionen</DialogTitle>
      <div style={{padding: '10px', background: '#000' }}>
        <div style={{ display: 'flex', background: '#000' }}>
          <ToggleButtonGroup
            value={mapType}
            onChange={handleMapType}
            exclusive
            fullWidth
            aria-label="text alignment"
            sx={{ flex: 1, display: 'flex', justifyContent: 'center', marginBottom: '10px' }}
          >
            <ToggleButton value="light" sx={{ '&.Mui-selected': { backgroundColor: '#2065D1', color: 'white' } }}   >
              Light
            </ToggleButton>
            <ToggleButton value="dark" sx={{ '&.Mui-selected': { backgroundColor: '#2065D1', color: 'white' } }}   >
              Dark
            </ToggleButton>
            <ToggleButton value="normal" sx={{ '&.Mui-selected': { backgroundColor: '#2065D1', color: 'white' } }}>
              Normal
            </ToggleButton>
          </ToggleButtonGroup>
        </div> 
        <div style={{ display: 'flex', background: '#000' }}>
          <ToggleButtonGroup
            value={displayType}
            onChange={handleDisplayType}
            exclusive
            fullWidth
            aria-label="text alignment"
            sx={{ flex: 1, display: 'flex', justifyContent: 'center', marginBottom: '10px' }}
          >
            <ToggleButton value="images" sx={{ '&.Mui-selected': { backgroundColor: '#2065D1', color: 'white' } }}   >
              Bilder
            </ToggleButton>
            <ToggleButton value="running" sx={{ '&.Mui-selected': { backgroundColor: '#2065D1', color: 'white' } }}>
              Joggen
            </ToggleButton>
            <ToggleButton value="both" sx={{ '&.Mui-selected': { backgroundColor: '#2065D1', color: 'white' } }}>
              Beides
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <FormControlLabel    
        style={{background: ' #111'}}                
                    control={
                      <Checkbox
                        checked={differentColors}
                        onChange={handleDiffColors}
                        color="primary"
                      />
                    }
                    label={"Different Colors"}
                  />      
        </div>
                 
      </Dialog>
    <Fav>
      <Fab color="secondary" aria-label="add" onClick={handleOpenDialog}>
          <Iconify  icon={'mdi:filter'}  color="#fff" width={32} />
      </Fab>
      </Fav>


    <Card style={{ height: '500px', minHeight: '110%' ,background: '#000',
        marginTop: "-20px",}} >
      {/* Use MapContainer without LeafletProvider */}
      <MapContainer center={[0,0]} zoom={2} style={{ height: '100%', width: '100%', background: '#000' }}>
        {/* Use TileLayer with 'CartoDB.DarkMatter' provider */}
        <TileLayer url={(mapType==='dark') ? "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png" : (mapType==='light') ? `https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=${mapboxAccessToken}` : `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${mapboxAccessToken}`  } style={{ height: '100%', width: '100%',background: '#000' }}/>

        
        {(displayType==='running' || displayType==='both') && runs.map((coordinates, index) => (  
          <Polyline positions={coordinates} pathOptions={differentColors ? lineCols[index] : polylineStyle[index]}>
             <Popup   onOpen={() => handlePopupOpen(10)} 
              style={{
                background:  '#111 !important',
                padding: '0px',
              }}
              onClose={handlePopupClose}>
              <a target='__blank' style={{width: '100%', textAlign: 'center', display: "block"}} href={`/dashboard/run#${dates[index].id}`}>{dates[index].date}</a>
              <p style={{width: '100%', textAlign: 'center'}}>{dates[index].distance} km</p>
              <ImageWithAuthorization key={index} circle={dates[index]} />
            </Popup>          
          </Polyline>
       ))} 
       
       {(displayType==='images' || displayType==='both') && circles.map((circle, index) => (
          <Circle key={index} center={circle.position} radius={circle.radius} pathOptions={{ color: (mapType === 'dark') ? '#2065d1' : '#f00' }}>
            <Popup>
              <a target='__blank' href={`/dashboard/day#${circle.date}`} style={{width: '100%', textAlign: 'center', display: "block"}}>{circle.date}
                <ImageWithAuthorization key={index} circle={circle} />
                </a>
            </Popup>          
          </Circle>
        ))}
      </MapContainer>
     </Card>
    {/* <Container maxWidth="xl">  
        <Grid container spacing={10} style={{ marginTop: '-50px', paddingLeft: isMobile ? '20px' : '0px', paddingRight: isMobile ? '20px' : '0px' }}>
      
    {away.map((aw, index) => (
        <Grid item xs={12} md={6} lg={4} key={index}>
           
          <div style={cardWrapperStyle}>
            <div style={labelStyle}>{aw.count}</div>
            <Card style={cardStyle}>
            <ImageWithAuthorization key={index} circle={aw} />
            </Card>
            <div style={labelStyle2}>{aw.place}</div>
          </div>
        </Grid>
      ))}
      </Grid>
        </Container> */}
    
    </>
  );
}
