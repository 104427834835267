import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';

// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Grid, Container, Typography, Button, Fab, Popover, Card, CardHeader } from '@mui/material';
// components
import React, { useContext,useState, useEffect  } from 'react';
import { useLocation } from 'react-router-dom';
import { decode } from '@mapbox/polyline';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Searchbar from './Searchbar';
import Iconify from '../components/iconify';

import CONFIG from "../config";
import { fShortenNumber } from '../utils/formatNumber';
// sections
import {
  AppTasks,
  AppTask,
  AppPost,
  AppNewsUpdate,
  AppRunRoute,
  AppDay,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppTopics,
  AppAuthorsActive,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import RunInfoTable from './RunInfoTable';

// ----------------------------------------------------------------------

export default function RunDetails() {
  const Fav = styled('fab')(({ theme }) => ({
    position: 'fixed',
    zIndex: 500,
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  }));

  const [user, setUser] = useState(null);
  
  const [imageDays, setImageDays] = React.useState('dates');
  const [perKm, setPerKm] = React.useState([]);


  useEffect(() => {  
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
      return;
    }
    fetch(`${CONFIG.BACKEND_URL}/api/user`, {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` }
    }).then(response => {
      console.log(response)
      setUser(response.json().user);
    })
    .catch(error => {
      console.log(error);
      window.location.href = '/login';
    })
  }, []);

  const options = ['neueste', 'positiv', 'neutral', 'negativ', 'unsicher'];
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState("");
  const [dailyCards, setDailyCards] = useState([]);
  
  
  const onSearch = (event) => {
    setSearchTerm(event);
  };
  console.log('DashboardAppPage: searchTerm', searchTerm);

  async function fetchData(url) {
    const token = localStorage.getItem('token');
    console.log(token)
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    const data = await response.json();
    return data;    
  }
  async function postData(url, payload) {
    const token = localStorage.getItem('token');
    console.log(token)
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    return data;
  }
  
  const removeLabel = (index) => {
    const updatedLabels = [...topicDataDisplayed];
    updatedLabels.splice(index, 1);
    setTopicDataDisplayed(updatedLabels);

    console.log(index)
  };
  const setTopicsUpdate = () => {
    console.log(topicDataDisplayed)
    setTopicData(topicDataDisplayed);

  };
  
  const [timeData, setTimeData] = useState([]);
  const [topicData, setTopicData] = useState([]);
  const [topicDataDisplayed, setTopicDataDisplayed] = useState([]);
  const [allTopics, setAllTopics] = useState([]);
  
  const [runs, setRuns] = useState([]);
  const [calories, setCalories] = useState("- ");
  const [elevLow, setElevLow] = useState("- ");
  const [elevHigh, setElevHigh] = useState("- ");
  const [prCount, setPrCount] = useState(0);
  const [dehydrationVolume, setDehydrationVolume] = useState("- ");
  const [weather, setWeather] = useState("");
  const [id, setId] = useState();

  const handleHashChange = () => {
    // Get the new hash value from window.location.hash
    const formattedDate = window.location.hash.replace('#','');
    //  setDate(formattedDate)
    console.log('Hash changed:', formattedDate);
    console.log(formattedDate)
    if(formattedDate.length>1){
      setId(formattedDate )
    }else{
      setId('9459570927')
    }
    
  };

  useEffect(() => {
    // Manually call the handleHashChange function to handle the initial hash
    handleHashChange();

    // Add the hash change event listener for future changes
    window.addEventListener('hashchange', handleHashChange);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  useEffect(() => {  
      Promise.all([
        postData(`${CONFIG.BACKEND_URL}/api/get_run_info`, {input: id}).then(( stance ) => {
            console.log(stance); 
            const parsed = JSON.parse(stance);
            console.log(parsed)
            if (parsed.length > 0){
                const latLngPoints = decode(parsed[0].summary_polyline);
                const totalPoints = latLngPoints.length;
                const center = latLngPoints.reduce(
                    (accumulator, point) => [accumulator[0] + point[0], accumulator[1] + point[1]],
                    [0, 0]
                );
                const averageCenter = [center[0] / totalPoints, center[1] / totalPoints];
                
                parsed[0].latlong = latLngPoints
                parsed[0].averageCenter = averageCenter
                parsed[0].zoom = 13
                if(parsed[0].distance > 20000){
                    parsed[0].zoom = 12
                }
                if(parsed[0].distance > 25000){
                    parsed[0].zoom = 11
                }
            }
            setRuns(parsed)   
        })

    ]).then(() => {
    });
  }, [id]);
  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };
  function isObject(obj) {
    // Check if the obj is not null and has a type of "object"
    return obj !== null && typeof obj === 'object';
  }
  
  
  useEffect(() => {  
      Promise.all([
        postData(`${CONFIG.BACKEND_URL}/api/get_run_info_detailed`, {input: id}).then(( stance ) => {
          console.log(stance)
            const parsed = JSON.parse(stance)
            const newArr = parsed.splits_metric;
            console.log("PERKMN")
            console.log(newArr)
            let arr =[];
            const maxElevation = 'elev_high' in parsed ? parsed.elev_high[0] : 0
            if(newArr !== undefined){
              arr = Object.entries(newArr).map(([key, value]) => {
                return value;
              });
              if(parsed.elev_high !== undefined){
                arr = arr[0];
              }
              let maxkm = 0;
              
              for (let i = 0; i < arr.length; i+=1) {
                let totalElevation = 0;  
                if (arr[i].elevation_difference !== undefined){          
                  for (let j = 0; j <= i; j+=1) {
                    totalElevation += arr[j].elevation_difference;                
                  }
                  arr[i].elevation_cum = totalElevation;
                }
              }
              
              for (let i = 0; i < arr.length; i+=1) {
                if (arr[i].elevation_cum > maxkm){
                  maxkm = arr[i].elevation_cum;                
                }
              }

              for (let i = 0; i < arr.length; i+=1) {
                arr[i].totalElevation  = ('altitude' in arr[i] ? arr[i].altitude: (arr[i].elevation_cum + maxElevation - maxkm)); // Initialize with parsed.elev_low[0]
              }
              setPerKm(arr)
            }
            if (parsed.dehydration_volume !== undefined){
              setDehydrationVolume(parsed.dehydration_volume)
            }
            if (parsed.calories !== undefined){
              if(isObject(parsed.calories)){
                setCalories(parsed.calories[0]) 
              }else{
                setCalories(parsed.calories) 

              }
            }
            setElevHigh(maxElevation)
            const weatherInfo = parsed.features;
            if(weatherInfo !== undefined){
              try{
                const { conditions, temperature, humidity } = weatherInfo[0].attributes;
                setWeather(`${conditions.charAt(0).toUpperCase() + conditions.slice(1)} at ${temperature}°C<br>Wind: ${weatherInfo[0].attributes.wind_speed} km/h<br>Humidity: ${humidity}%`);
              
              }catch (error) {            
                console.log("Error while parsing weather info")    
              }
            }
            if(parsed.elev_low !== undefined){
              setElevLow(parsed.elev_low[0])
            }else if(arr.length > 0 && arr[0].altitude !== undefined ){
              let maxkm = 0;
              for (let i = 0; i < arr.length; i+=1) {
                if (arr[i].altitude > maxkm){
                  maxkm = arr[i].altitude;                
                }
              }
              setElevHigh(maxkm)

              maxkm = Infinity;
              for (let i = 0; i < arr.length; i+=1) {
                if (arr[i].altitude < maxkm){
                  maxkm = arr[i].altitude;                
                }
              }
              setElevLow(maxkm)

            }
            console.log("arrr")
            if(parsed.best_efforts !== undefined){
              arr = Object.entries(parsed.best_efforts).map(([key, value]) => {
                return value;
              });
              arr = arr[0];
              const res = [];
              let prStr = ""
              for (let i = 0; i < arr.length; i+=1) {
                if(arr[i].pr_rank ===1){
                  res.push(arr[i])
                  prStr += `${arr[i].name} (${formatTime(arr[i].moving_time)} h)<br>`
                }
              }
              setPrCount(prStr)
           }            
        })

    ]).then(() => {
    });
  }, [id]);

  useEffect(() => {  
    if(runs.length > 0){
        Promise.all([
            postData(`${CONFIG.BACKEND_URL}/api/get_images_day`, {input: runs[0].start_date_local}).then(( stance ) => {
            
            console.log(stance); 
            const parsed = JSON.parse(stance);
            console.log(parsed)
            setDailyCards(parsed)   
                
            })

        ]).then(() => {
        });
    }
  }, [runs]);


  return (
    <>
      <Helmet>
        <title> Blue </title>
      </Helmet>
      
      

      <Container maxWidth="xl" >
        
      <Grid container spacing={2} >     
        
         {runs.length > 0 && (<><Grid item xs={12} md={6} lg={6} >
            <AppRunRoute latLngPoints={runs[0].latlong} center={runs[0].averageCenter} zoom={runs[0].zoom}/>
            <Grid item xs={12} md={12} lg={12} style={{marginTop: '10px'}} >            
            {perKm.length> 0  &&(<AppWebsiteVisits
              title="Pace vs Elevation"
              displayStance
              displayRelevance
              height={267}
              
              limitStance
              chartLabels={perKm.map((entry) => ('split' in entry ? entry.split :(`${(entry.distance / 1000).toFixed(0)}`)))}

              chartData={[
                {
                  name: 'Pace',
                  type: 'line',
                  fill: 'solid',
                  data: perKm.map((entry) => ('average_speed' in entry ? entry.average_speed * 3.6 : ('speed' in entry ? entry.speed : 0))),
                },{
                  name: 'Elevation',
                  type: 'line',
                  fill: 'solid',
                  data: perKm.map((entry) => (entry.totalElevation)),
                },
              ]}
            />)}
          </Grid>
            </Grid>
            

            <Grid item xs={12} md={6} lg={6} >
                <Card sx={{  width: '100%', height:'710px', background: '#222', color: '#eee' }}>
                    <a href={`/dashboard/day#${runs[0].start_date_local}`}><CardHeader title={runs[0].start_date_local} subheader={runs[0].place} /></a>
                    <RunInfoTable run={runs[0]} calories={calories} elevHigh={elevHigh}  elevLow={elevLow} prCount={prCount} weather={weather} dehydrationVolume={dehydrationVolume} />
                </Card>
               
            </Grid>

            
            
                
                
            <Grid item xs={12} md={12} lg={12}>
                <AppDay data={dailyCards} runs = {runs} fShortenNumber={fShortenNumber} showOnlyImages />
            </Grid>
            
            </>

            
            )}
          </Grid>
         
      </Container>
    </>
  );
}
