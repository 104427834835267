import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState ,useEffect } from 'react';
import Alert from '@mui/material/Alert';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  Snackbar,
  TableRow,
  MenuItem,
  Grid,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  Pagination,
  TablePagination,
  CircularProgress, LinearProgress
} from '@mui/material';
import { Icon } from '@iconify/react';
// components
import { useLocation } from 'react-router-dom';
import {
  Upload
} from '../sections/@dashboard/app';

import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock

import { fShortenNumber } from '../utils/formatNumber';
// ----------------------------------------------------------------------


import CONFIG from "../config";

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'date', label: 'Datum', alignRight: false },
  { id: 'type', label: 'Typ', alignRight: false },
  { id: 'tags', label: 'Tags', alignRight: false },
  { id: 'size', label: 'Size', alignRight: false },
  { id: 'favorite', label: 'Favorit', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  
  if (query) {
    const filteredArray = array.filter((_user) => {
      const lowerCaseQuery = query.toLowerCase();
      const nameMatch = _user.name.toLowerCase().indexOf(lowerCaseQuery) !== -1;
      const tagsMatch = _user.tags.toLowerCase().indexOf(lowerCaseQuery) !== -1;
      return nameMatch || tagsMatch;
    });
    
    const stabilizedFiltered = filteredArray.map((el, index) => [el, array.indexOf(el)]);
    
    stabilizedFiltered.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    
    return stabilizedFiltered.map((el) => el[0]);
  }     
  stabilizedThis.sort((a, b) => {
  const order = comparator(a[0], b[0]);
  if (order !== 0) return order;
  return a[1] - b[1];
  });      
  return stabilizedThis.map((el) => el[0]);
}
function formatFileSize(sizeInBytes) {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];

  if (sizeInBytes === 0) return '0 B';

  const i = parseInt(Math.floor(Math.log(sizeInBytes) / Math.log(1024)), 10);
  const size = (sizeInBytes / (1024 ** i)).toFixed(2);

  return `${size} ${units[i]}`;
}


export default function ComparePage() {
  const location = useLocation();

  const [USERLIST, setUserList] = useState([]);

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(1);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');
  const [totalUsers, setTotalUsers] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isMobile, setIsMobile] = useState(false);
  
  const [uploadResult, setUploadResult] = useState(null);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 50); 
  };



  const handleFilterByName = (event) => {
    setPage(1);
    setFilterName(event.target.value);
  };

  const extensionIcons = {
    png: { icon: 'file-image', color: '#e57373' },
    jpg: { icon: 'file-image', color: '#e57373' },
    jpeg: { icon: 'file-image', color: '#e57373' },
    gif: { icon: 'file-image', color: '#e57373' },
    bmp: { icon: 'file-image', color: '#e57373' },
    pdf: { icon: 'file-pdf', color: '#64b5f6' },
    docx: { icon: 'file-word', color: '#81c784' },
    ppt: { icon: 'file-powerpoint', color: '#ffab91' },
    pptx: { icon: 'file-powerpoint', color: '#ffab91' },
    xls: { icon: 'file-excel', color: '#4db6ac' },
    xlsx: { icon: 'file-excel', color: '#4db6ac' },
    mp3: { icon: 'file-music', color: '#9575cd' },
    wav: { icon: 'file-music', color: '#9575cd' },
    ogg: { icon: 'file-music', color: '#9575cd' },
    mp4: { icon: '', color: '#f06292' },
    avi: { icon: '', color: '#f06292' },
    mkv: { icon: '', color: '#f06292' },
    mov: { icon: '', color: '#f06292' },
    // Add more extensions and corresponding Iconify icons/colors as needed
  };

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const [user, setUser] = useState(null);

  useEffect(() => {  
    setIsMobile(window.innerWidth <= 768);
    
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
      return;
    }
    fetch(`${CONFIG.BACKEND_URL}/api/user`, {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` }
    }).then(response => {
      console.log(response)
      setUser(response.json().user);
    })
    .catch(error => {
      console.log(error);
      window.location.href = '/login';
    })
  }, []);
  
  async function fetchData(url) {
    const token = localStorage.getItem('token');
    console.log(token);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    
    let  data = await response.json();
    try {
      data = JSON.parse(data);    
    } catch (error) {
      console.log("err")
    }
  
    return data;
  }
  
  const [diskInfo, setDiskInfo] = useState({ memTotalGb: 0, memAvailGb: 0 });

  useEffect(() => {  
    async function fetchUsers() {
      const  profiles  = await fetchData(`${CONFIG.BACKEND_URL}/api/get_files`);
      console.log(profiles)
      setTotalUsers(profiles.length)
      setUserList(profiles.map((item) => ({
        id: item.id,
        avatarUrl: item.path.substring(item.path.lastIndexOf(".") + 1).toLowerCase(),
        name: item.path.substring(item.path.lastIndexOf("/") + 1, item.path.length),
        date: item.date,
        favorite: item.favorite,
        size: item.size,
        tags: item.image_tags,
        extension: item.path.substring(item.path.lastIndexOf(".")+1).toLowerCase()
      })));
    }
    fetchUsers();
    async function fetchStorage() {
      const  profiles  = await fetchData(`${CONFIG.BACKEND_URL}/api/get_storage_info`);
      console.log(profiles)
      setDiskInfo(profiles)
    }
    fetchStorage();
  }, [uploadResult]);
  
  const handleUploadResult = (status) => {
    setUploadResult(status);
  };

  const handleSnackbarClose = () => {
    setUploadResult(null);
  };


  const { memTotalGb, memAvailGb } = diskInfo;
  const usedSpace = memTotalGb - memAvailGb;
  const filledPercentage = (usedSpace / memTotalGb) * 100;

  return (
    <>
      <Helmet>
        <title> Files | Blue </title>
      </Helmet>


      <Container>
        
        <Card style={{background: '#222', color:'#ccc'}}>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
          <Grid container spacing={2} style={{ marginTop: '-15px' }}>
          <Grid item xs={6} md={6} lg={6}>

          <Upload date={"Dokumente"} isMobile={isMobile}  handleUploadResult={handleUploadResult}/>
           </Grid>
                    <Grid item xs={6} md={6} lg={6}>

              <Typography style={{textAlign: 'right', paddingRight:'50px', marginTop: '10px'}}>{memAvailGb} GB available</Typography>            </Grid>

              <Grid item xs={12} md={12} lg={12}>

              <LinearProgress variant="determinate" value={filledPercentage} />
            </Grid>
            </Grid>
            <TableContainer sx={{ minWidth: 800, width: '100%' }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  firstCheckBox
                />
                <TableBody>
                  {filteredUsers.slice((page-1) * rowsPerPage, (page-1) * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, name, avatarUrl, date, favorite, extension, tags,size} = row;
                    const selectedUser = selected.indexOf(name) !== -1;
                    console.log(extension)
                    const { icon, color } = extensionIcons[extension] || { icon: 'file', color: '#9e9e9e' }; 

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser} >
                      
                        <TableCell padding="checkbox"  style={{ border: '1px solid #000' }}>
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none"  style={{ border: '1px solid #000' }}> 
                            
                        <a href={`${CONFIG.BACKEND_URL}/api/images/${date}/${name}`} target='_blank' rel="noreferrer" >
                          <Stack direction="row" alignItems="center" spacing={2}>
                          <Avatar sx={{ background: color, marginLeft: '10px' }}>
                            {(name.toLowerCase().includes('.mp4') || name.toLowerCase().includes('.wmv') || name.toLowerCase().includes('.avi') || name.toLowerCase().includes('.mov')) && <img style={{height: '100%', width:'100%', objectFit: 'cover'}} src={`${CONFIG.BACKEND_URL}/api/images/${date}/compressed/${name.replace(/\.[^.]+$/, '')}.jpg`} alt={name} />}
                            <Icon icon={`mdi:${icon}`} color="#fff" width={32} /></Avatar>
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </a>
                        </TableCell>
                        
                        <TableCell  style={{ border: '1px solid #000' }} align="left"><a href={`${CONFIG.BACKEND_URL}/dashboard/day#${date}`} target='_blank' rel="noreferrer" >{date}</a></TableCell>
                        
                        <TableCell  style={{ border: '1px solid #000' }} align="left">{extension}</TableCell>
                        <TableCell  style={{ border: '1px solid #000' }} align="left">{tags}</TableCell>
                        <TableCell  style={{ border: '1px solid #000' }} align="left">{formatFileSize(size)}</TableCell>
                        <TableCell   style={{ border: '1px solid #000' }} align="left">
                          <Label color={favorite ? 'success'  : 'error'}>{favorite ? 'Yes' : 'No'}</Label>
                        </TableCell>
                       
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isNotFound && (
                  <TableBody style={{background: '#222'}}>
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',background: '#222'
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }}>
            <Pagination
              color="primary"
              shape="rounded"
              count={Math.ceil(filteredUsers.length / rowsPerPage)}
              page={page}
              style={{ margin: '0 auto' }}
              onChange={handleChangePage}
            />
          </div>

            
        </Card>
      </Container>



      <Snackbar open={uploadResult !== null} autoHideDuration={3000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={uploadResult === 'success' ? 'success' : 'error'}>
            {uploadResult === 'success' ? 'Files uploaded successfully!' : 'Failed to upload files.'}
          </Alert>
        </Snackbar>
    </>
  );
}