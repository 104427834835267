import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Grid, Container, Typography, Button, Divider,List,ListItem, ListItemText, Fab, Card, Popover, Box, Chip, DialogContent, Dialog, Checkbox , FormControlLabel, DialogTitle, CardHeader} from '@mui/material';
// components
import React, { useContext,useState, useEffect  } from 'react';
import { useLocation } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Searchbar from './Searchbar';
import Iconify from '../components/iconify';

import CONFIG from "../config";
import { fShortenNumber } from '../utils/formatNumber';
// sections
import {
  AppTasks,
  AppTask,
  AppPost,
  AppNewsUpdate,
  AppMaps,
  AppDay,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppTopics,
  AppAuthorsActive,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import PersonDetails from './PersonDetails';

import { SearchContext } from '../context/SearchContext';
// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const Fav = styled('fab')(({ theme }) => ({
    position: 'fixed',
    zIndex: 500,
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  }));

  const [user, setUser] = useState(null);
  const [kmYear, setKmYear] = useState(0);
  const [faces, setFaces] = useState(false);
  const [noLimits, setLimits] = useState(false);
  const [person, setPerson] = useState([]);
  const [filterOptions, setFilterOptions] = useState(['date', 'place', 'keywords', 'names', 'description']);
  


  const [imageDays, setImageDays] = React.useState('dates');
  const [isMobile, setIsMobile] = useState(false);

  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const handleToggleImageDays = (newAlignment) => {
    setImageDays(newAlignment);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Check on initial render

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {  
    setIsMobile(window.innerWidth <= 768);
    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
      return;
    }
    fetch(`${CONFIG.BACKEND_URL}/api/user`, {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` }
    }).then(response => {
      setUser(response.json().user);
    })
    .catch(error => {
      window.location.href = '/login';
    })
  }, []);

  const { searchTerm, setSearchTerm } = useContext(SearchContext);
  const [dailyCards, setDailyCards] = useState([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  
  const fetchSearch = async (searchTerm, imgDays) => {
    try {
      const responseData = await postData(`${CONFIG.BACKEND_URL}/api/query`, { input: searchTerm, imageDays: imgDays, columns: selectedOptions, showFaces:faces, limit: noLimits ? -1 : 1000});
      return JSON.parse(responseData);
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };
  const fetchperson = async (searchTerm) => {
    try {
      const responseData = await postData(`${CONFIG.BACKEND_URL}/api/query_person`, { input: searchTerm});
      return JSON.parse(responseData).slice(0,5);
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  async function fetchData(url) {
    const token = localStorage.getItem('token');
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    const data = await response.json();
    return data;    
  }
  async function postData(url, payload) {
    const token = localStorage.getItem('token');
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    return data;
  }
  
  
  const [timeData, setTimeData] = useState([]);
  const [allTopics, setAllTopics] = useState([]);
  
  const [runs, setRuns] = useState([]);
  const [allsuggestions, setAllSuggestions] = useState([]);
  const [classificationSuggestions, setClassificationSuggestions] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [daysCountdown, setDaysCountdown] = useState(0);
  const [events, setEvents] = useState({});

  useEffect(() => {  
      Promise.all([
        fetchData(`${CONFIG.BACKEND_URL}/api/runs`).then(( stance ) => {          
          const parsed = JSON.parse(stance);
          setRuns(parsed);
        })
    ]).then(() => {
    });
  }, []);
  useEffect(() => {  
      Promise.all([
        fetchData(`${CONFIG.BACKEND_URL}/api/get_calendar`).then(( parsed ) => {          
          
          const groupedEvents = {};
          Object.keys(parsed).forEach(dateTime => {
            const [date, time] = dateTime.split('T');
            
            let setDate = date;
            if (date === formatDate(new Date())){
              setDate = "Heute"
            }
            if (!groupedEvents[setDate]) {
              groupedEvents[setDate] = [];
            }
            console.log(setDate)
            groupedEvents[setDate].push({ time, value: parsed[dateTime] });
          });
          console.log(groupedEvents)

          setEvents(groupedEvents);
        })
    ]).then(() => {
    });
  }, []);

  useEffect(() => {  
      Promise.all([
        fetchData(`${CONFIG.BACKEND_URL}/api/suggestions`).then(( stance ) => {
          const dataArray =  Array.from(new Set(JSON.parse(stance).map(item => item.name.trim())));
          setAllSuggestions(dataArray)
        })
    ]).then(() => {
    });
  }, []);
  useEffect(() => {  
      Promise.all([
        fetchData(`${CONFIG.BACKEND_URL}/api/suggestions_classification`).then(( stance ) => {
          const dataArray =  Array.from(new Set(JSON.parse(stance).map(item => item.name.trim())));
          setClassificationSuggestions(dataArray)
        })

    ]).then(() => {
    });
  }, []);
  function calculateDaysUntilDate(targetDate) {
    const today = new Date(); // Get the current date
  
    // Convert the targetDate to a Date object
    const targetDateObj = new Date(targetDate);
  
    // Calculate the difference in milliseconds between the two dates
    const timeDifference = targetDateObj.getTime() - today.getTime();
  
    // Calculate the number of days by dividing the time difference by the number of milliseconds in a day
    const daysUntilDate = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  
    return daysUntilDate;
  }
  
  useEffect(() => {  
    setDaysCountdown(calculateDaysUntilDate("2023-11-13"))

  }, []);

  useEffect(() => {
    const delay = 500; // Set the debounce delay in milliseconds

    const debounceSearch = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => clearTimeout(debounceSearch);
  }, [searchTerm]);

  useEffect(() => {
    if(debouncedSearchTerm.length === 0){
      setSuggestions([])
    }else{
      // Filter the options based on user input and return the top 5 matches
      const filteredOptions = (imageDays ==='classification' ? classificationSuggestions : allsuggestions) 
      .filter((option) => option.toUpperCase().includes(debouncedSearchTerm.toUpperCase()))
      .sort((a, b) => {
        const aMatchIndex = a.toUpperCase().indexOf(debouncedSearchTerm.toUpperCase());
        const bMatchIndex = b.toUpperCase().indexOf(debouncedSearchTerm.toUpperCase());

        // Sort by the index of the match, closer to the beginning means higher relevance
        return aMatchIndex - bMatchIndex;
      });

      if (filteredOptions.length === 1 && filteredOptions[0].toUpperCase() === debouncedSearchTerm.toUpperCase()) {
        setSuggestions([])
      }else if(isMobile){
          setSuggestions(filteredOptions.slice(0, 8))
      }  else{
        setSuggestions(filteredOptions.slice(0, 10))
      }
    }
  }, [debouncedSearchTerm]);


  useEffect(() => {  
    if(imageDays==='images'){
      setFilterOptions([...filterOptions, "path"])
    }else{
      setFilterOptions(filterOptions.filter((item) => item !== "path"))
      setSelectedOptions(selectedOptions.filter((item) => item !== "path"))
    }
}, [imageDays]);

  useEffect(() => {
    async function fetch() {
      const parsedData = await fetchSearch(debouncedSearchTerm, imageDays);
      if (parsedData !== null) {
        setDailyCards(parsedData);
      }
    }
    async function fetchPerson() {
      const parsedData = await fetchperson(debouncedSearchTerm);
      if (parsedData !== null) {
        setPerson(parsedData);
      }
    }

    fetch();
    fetchPerson();
    
  }, [imageDays, debouncedSearchTerm, selectedOptions, faces, noLimits]);

  

  useEffect(() => {  
      Promise.all([
        fetchData(`${CONFIG.BACKEND_URL}/api/monthlyRuns`).then(( stance ) => {
          const parsed = JSON.parse(stance);
          const result = parsed.map(curr => [`${curr.year}-${curr.month}`, curr.distance]);
          setTimeData(result);
          const currentDate = new Date();
          const currentYear = currentDate.getFullYear();
          const dist = parsed.reduce((total, entry) => total + (entry.year === currentYear ? entry.distance : 0), 0);
          setKmYear((dist / 1000).toFixed(1))
        })

    ]).then(() => {
    });
  }, []);
  const [dialogOpen, setDialogOpen] = useState(false);
  //  setSearchTerm(`${curr.vorname} ${curr.nachname}`)
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  
  
 
  const handleCloseDialog = () => {
      setDialogOpen(false);
  }; 
  const handleFaceChange = () => {
      setFaces(!faces);
  };
  const handleLimits= () => {
      setLimits(!noLimits);
  };

  const handleCheckboxChange = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const formatDate = (date) => {
    if(date.length===10){
      return date;
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  
  const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  
  //  Download all
  
  const [downloadLink, setDownloadLink] = useState(false);
  const [downloadLinkFN, setDownloadLinkFN] = useState(false);
  
  useEffect(() => {  
      fetchData(`${CONFIG.BACKEND_URL}/api/get_all_stories`).then(( data ) => {   
      const p = JSON.parse(data);       
      console.log("data")
      console.log(data)
      const currentDate = new Date().toISOString().slice(0, 10);

      setDownloadLinkFN(`stories_${currentDate}.json`);
      const blob = new Blob([data], { type: 'application/json' });
      setDownloadLink(URL.createObjectURL(blob));  
    })
  }, []);
  
  const [downloadLinkPerson, setDownloadLinkPerson] = useState(false);
  const [downloadLinkFNPerson, setDownloadLinkFNPerson] = useState(false);
  
  useEffect(() => {  
      fetchData(`${CONFIG.BACKEND_URL}/api/get_persons`).then(( data ) => {   
      const p = JSON.parse(data);       
      console.log("data")
      console.log(data)
      const currentDate = new Date().toISOString().slice(0, 10);

      setDownloadLinkFNPerson(`persons_${currentDate}.json`);
      const blob = new Blob([data], { type: 'application/json' });
      setDownloadLinkPerson(URL.createObjectURL(blob));  
    })
  }, []);
  
  
  return (
    <>
      <Helmet>
        <title> Blue | Dashboard </title>
      </Helmet>
      
    
      <Fav>
      <Fab color="secondary" aria-label="add">
          <Iconify  icon={'bi:filter'}  onClick={handleOpenDialog} color="#fff" width={32} />
      </Fab>
      </Fav>
      <Dialog  open={dialogOpen} onClose={handleCloseDialog}>
      <DialogTitle style={{width: '100%', background: '#000'}}>Such-Optionen</DialogTitle>
      <DialogContent style={{width: '100%', background: '#111'}}> 
            <FormControlLabel                    
                    control={
                      <Checkbox
                        checked={noLimits}
                        onChange={handleLimits}
                        color="primary"
                      />
                    }
                    label={"No Limits"}
                  />               
                {imageDays === 'images' && (
                  <FormControlLabel                    
                    control={
                      <Checkbox
                        checked={faces}
                        onChange={handleFaceChange}
                        color="primary"
                      />
                    }
                    label={"Faces"}
                  />)}
          </DialogContent>
          <DialogContent style={{width: '100%', background: '#111'}}>
                {filterOptions.map((option) => (
                  <div key={option}>
                  <FormControlLabel
                    key={option}
                    control={
                      <Checkbox
                        checked={selectedOptions.includes(option)}
                        onChange={() => handleCheckboxChange(option)}
                        color="primary"
                      />
                    }
                    label={option}
                  />
                  </div>
                ))}

          </DialogContent>
      </Dialog>

      

      <Container maxWidth="xl">  
      <Grid container spacing={2}  style={{marginTop: '-40px'}}>       
     
      <Grid item xs={12} md={12} lg={12} >
      <Grid container spacing={0.5} style={{ marginTop: suggestions.length === 0 && person.length === 0 ? '-5px' : '10px' }}>

        <Box style={{ display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        gap: '5px',}}>

        <PersonDetails person={person} isMobile={isMobile}/>

       {suggestions.map((xlabel) => (
          <Chip
            label={xlabel}
            onClick={() => setSearchTerm(xlabel)}
            style={{
              background: '#111',
              color: '#eee',
            }}
          />
        ))}

        </Box>
        </Grid>    
        </Grid>    
        {searchTerm.length > 0 && (
            <Grid item xs={12} md={12} lg={12}>
            <AppDay showDecider data={dailyCards} handleToggle={handleToggleImageDays} imageDays={imageDays} fShortenNumber={fShortenNumber}  runs={[]}/>
          </Grid>
        )}  
        {searchTerm.length === 0 && (
        <>
        <Grid item xs={12} md={6} lg={6} >
          <Card style={{background: '#111', paddingBottom: '20px'}}>
            <a  style={{textDecoration: "none", color: "#7EB0FF"}} href={`/dashboard/day#${formatDate(new Date())}`}><CardHeader  title={`${formatDate(new Date())}`} subheader={`${weekdays[new Date().getDay()]}`}/></a>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={6} >
          <Card style={{background: '#111', paddingBottom: '20px'}}>
            <CardHeader  title={`Have a nice day ;)`} subheader={"Today"}/>
          </Card>
        </Grid>

        
        <Grid item xs={12} md={12} lg={6} >
          <Card style={{background: '#111',height: '350px', paddingBottom: '20px', overflowY: 'auto', padding:' 10px' , paddingLeft: '20px',
        backgroundImage: `url(/assets/amrum.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',}}>
              
              <List>
                  {Object.keys(events).map(date => (
                    <div key={date}>
                      <Typography variant="subtitle1" color="primary">{date}</Typography>
                      <Divider />
                      {events[date].map(event => (
                        <ListItem key={`${date}-${event.time}`}>
                          <ListItemText primary={event.time} secondary={event.value} />
                        </ListItem>
                      ))}
                    </div>
                  ))}
                </List>
          </Card>
          </Grid>

          <Grid item xs={12} md={12} lg={6} >            
            <AppWebsiteVisits
              title={`Joggen (${kmYear} km)`}
              displayStance
              datetime
              height={250}
              
              limitStance
              chartLabels={timeData.map((entry) => (entry[0]))}

              chartData={[
                {
                  name: 'Distanz',
                  type: 'bar',
                  fill: 'solid',
                  data: timeData.map((entry) => (entry[1] / 1000)),
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12} >
            <Grid item xs={12} md={12} lg={12} >
            <Grid container spacing={2}>
              <Grid item xs={6} md={6} lg={6} >
                <Card style={{background: '#111', padding: '10px', marginBottom: '10px', marginTop: "-5px", textAlign: "center", width: "100%"}}>
                      <a href={downloadLink} download={downloadLinkFN} style={{textAlign: "center", width: "100%"}}>
                Download all Stories
              </a></Card>
              </Grid>
              <Grid item xs={6} md={6} lg={6} >
                <Card style={{background: '#111', padding: '10px', marginBottom: '10px', marginTop: "-5px", textAlign: "center", width: "100%"}}>
                      <a href={downloadLinkPerson} download={downloadLinkFNPerson} style={{textAlign: "center", width: "100%"}}>
                Download all Persons
              </a></Card>
              </Grid>
              </Grid>
            </Grid>
          <Grid container spacing={2}>

            <AppCurrentVisits
              data={ runs.slice(0, 9) }
              fShortenNumber = {fShortenNumber}
            />
          </Grid>
          </Grid></>
        )}       
        </Grid>   
      </Container>
    </>
  );
}
