import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Grid, Card, CardHeader } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// utils
import { fNumber } from '../../../utils/formatNumber';
// components
import { useChart } from '../../../components/chart';
import CONFIG from "../../../config";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const StyledChartWrapper = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

AppCurrentVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.array,
};

export default function AppCurrentVisits({ data, fShortenNumber, ...other }) {
  const [hoveredCardId, setHoveredCardId] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [imageUrls2, setImageUrls2] = useState([]);

  const handleCardHover = (cardId) => {
    setHoveredCardId(cardId);
  };

  const handleCardMouseLeave = () => {
    setHoveredCardId(null);
  };
  useEffect(() => {
    async function fetchImages() {
      const token = localStorage.getItem('token');
      const imagePromises = data.map(async (item) => {
        const path = `${CONFIG.BACKEND_URL}/api/runs/${item.id}.png`;

        const response = await fetch(path, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          },
        });
        const blob = await response.blob();
        return URL.createObjectURL(blob);
      });

      const imagePromises2 = data.map(async (item) => {
        const path = `${CONFIG.BACKEND_URL}/api/runs/${item.id}_streets.png`;

        const response = await fetch(path, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          },
        });
        const blob = await response.blob();
        return URL.createObjectURL(blob);
      });

      try {
        const imageDataUrls = await Promise.all(imagePromises);
        setImageUrls(imageDataUrls);
        const imageDataUrls2 = await Promise.all(imagePromises2);
        setImageUrls2(imageDataUrls2);
        
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    }

    fetchImages();
  }, [data]); 
  
  return (
    <>
      {data.map((item, index) => (       
        <Grid item xs={12} md={6} lg={4} key={item.id}>
          <a href={`/dashboard/run#${item.id}`} style={{ textDecoration: 'none' }}>
            <Card
              sx={{
                height: '100%',
                width: '100%',
                background: '#000',
                color: '#fff',
                position: 'relative',
              }}
              onMouseEnter={() => handleCardHover(item.id)}
              onMouseLeave={handleCardMouseLeave}
              {...other}
            >
              <CardHeader title={item.start_date_local} subheader={item.name} />
              <p
                style={{
                  float: 'right',
                  position: 'absolute',
                  top: '25px',
                  right: '25px',
                }}
              >{`${fShortenNumber(item.distance)}m`}</p>

              <LazyLoadImage
                src={hoveredCardId === item.id ? imageUrls2[index] : imageUrls[index]}
                alt={item.id}
                width="100%"
                height="250px"
                style={{ objectFit: 'cover', marginTop: '10px' }}
                effect="blur"
              />
            </Card>
          </a>
        </Grid>
      ))}
      </>
  );
}
