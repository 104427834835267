import { useEffect, useState, useContext } from 'react';

// @mui
import { styled } from '@mui/material/styles';
import { Card, Typography ,Input, Slide, Button, IconButton, InputAdornment, ClickAwayListener } from '@mui/material';
// utils
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { bgBlur } from '../utils/cssStyles';
// component
import Iconify from '../components/iconify';
import { SearchContext  } from '../context/SearchContext';
// ----------------------------------------------------------------------

import CONFIG from "../config";


const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const StyledSearchbar = styled('div')(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  top: 0,
  zIndex: 99,
  background: '#080C17',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  height: HEADER_MOBILE,
  padding: theme.spacing(0, 2),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: HEADER_DESKTOP,
    padding: theme.spacing(0, 3),
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar( { onSearch})  {
  const [open, setOpen] = useState(true);
  const { searchTerm, setSearchTerm } = useContext(SearchContext);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = () => {
    if (searchTerm.trim() !== '') {
      onSearch(searchTerm);
    }
    handleClose();
  };

  const handleChange = (event) => {
    onSearch(event.target.value);

    setSearchTerm(event.target.value);
  };
  const handleSearchTerm = (event) => {
    setSearchTerm(event.target.innerHTML);
    onSearch(event.target.innerHTML);
  };
  const handleSearchTerm2 = (event) => {
    setSearchTerm(event);
    onSearch(event);
  };
  const [value, setValue] = useState('');
  async function fetchData(url) {
    const token = localStorage.getItem('token');
    console.log(token)
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    const data = await response.json();
    return data;
  }
  const [options, setOptions] = useState([]);

 /* useEffect(() => {
    fetchData('${CONFIG.BACKEND_URL}/api/suggestions')
      .then((response) => setOptions(JSON.parse(response).map(curr => curr.name)));
    }, []);
*/

  return (
    <Card style = {{ marginTop: '-20px', display: 'flex', marginBottom: '15px', background: '#000'}} >
    <StyledSearchbar >
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        fullWidth
        options={options}
        onClick={() => {
          document.activeElement.blur();
        }}
        onChange={handleSearchTerm}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            fullWidth
            disableUnderline
            value={searchTerm}
            onChange={handleChange}
            label="Suchen"
            placeholder='Suchen'
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                handleSearchTerm2(event.target.value);
              }
            }}
            InputProps={{
              ...params.InputProps,
              type: 'search',
              style: { color: 'white' }
            }}
            
          />
        )}
      />
      
      <Button variant="contained" onClick={handleSearch} style = {{ height: '60px', marginLeft: '10px', background: '#2065d1' }}>
        Search
      </Button>
      
      </StyledSearchbar>
      
      </Card>
  );
}
