import React, { useContext,useState, useEffect  } from 'react';

import { Dialog, DialogContent, Avatar, Chip, Grid, DialogTitle, Box } from '@mui/material';
import PersonInfoTable from './PersonTable';

import CONFIG from "../config";

export default function PersonDetails({person,isMobile}) {
    const [currentUser, setCurrentUser] = useState({});
    const [currentUserIndex, setCurrentUserIndex] = useState({});
    const [personDialogOpen, setPersonDialogOpen] = useState(false);
    const [imageUrls, setImageUrls] = useState([]);

    async function postData(url, payload) {
      const token = localStorage.getItem('token');
      console.log(token)
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      return data;
    }
    const fetchperson = async (searchTerm) => {
      try {
        const responseData = await postData(`${CONFIG.BACKEND_URL}/api/query_person`, { input: searchTerm});
        console.log(responseData)
        return JSON.parse(responseData).slice(0,5);
      } catch (error) {
        console.error('Error fetching data:', error);
        return [];
      }
    };
    const handleOpenPersonDialog = async(user, index) => {
        const perscurr = person.filter((item) => item.id===user)[0]
        const fetchPers = await fetchperson(`${perscurr.vorname} ${perscurr.nachname}`);
        let curr = [];
        if(fetchPers.length > 0){
          curr = fetchPers[0];
        }
        console.log(curr)
        setPersonDialogOpen(true);
        setCurrentUser(curr);        
        setCurrentUserIndex(index);        
      };
      const handleClosePersonDialog = () => {
        setPersonDialogOpen(false);
      };
      
  useEffect(() => {
    async function fetchImages() {
      const token = localStorage.getItem('token');
      const imagePromises = person.map(async (item) => {
        const path = `${CONFIG.BACKEND_URL}/api/person/${item.nachname}_${item.vorname}/profile.jpeg`
        
        

        const response = await fetch(path, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          },
        });
        const blob = await response.blob();
        return URL.createObjectURL(blob);
      });

      try {
        const imageDataUrls = await Promise.all(imagePromises);
        setImageUrls(imageDataUrls);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    }

    fetchImages();
  }, [person]); 
      
return (
    <>
   
          {person.map((item, index) => (<>
          <Chip
            label={`${item.vorname} ${item.nachname}`}
            avatar={
              <Avatar sx={{ position: 'relative' }}>
                <img
                  style={{ objectFit: 'cover', zIndex: 5, height:'100%' }}
                  src={imageUrls[index]}
                  alt=""
                />
                <span
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '12px', 
                  }}
                >
                  {item.nachname && item.vorname ? `${item.vorname[0]}${item.nachname[0]} ` : ""}
                </span>
              </Avatar>
            }
            onClick={() => handleOpenPersonDialog(item.id, index)}
            style={{
              background: '#111',
              color: '#eee',
            }}
          />

          </>
        ))}

    <Dialog open={personDialogOpen} onClose={handleClosePersonDialog}>
      <div style={{ minWidth:isMobile? undefined : 500, background: '#111'}}>
      <DialogContent style={{ width: isMobile? undefined : '100%', background: '#222', textAlign: 'center' }}>
        
        <Avatar sx={{ position: 'relative' , width: '120px',
            height: '120px',
            margin: '0 auto',
            marginBottom: '10px',}}>
                <img
                  style={{ objectFit: 'cover', zIndex: 5 }}
                  src={imageUrls[currentUserIndex]}
                  alt=""
                />
                <span
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    color: '#303030',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '33px', 
                  }}
                >
                  {currentUser.vorname && currentUser.nachname ? `${currentUser.vorname[0]}${currentUser.nachname[0]} ` : ""}
                </span>
              </Avatar>
        <DialogTitle>{`${currentUser.vorname} ${currentUser.nachname}`}</DialogTitle>
        <PersonInfoTable data={currentUser}/>
      </DialogContent>
      </div>
    </Dialog></>
)}