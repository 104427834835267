
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { MapContainer, TileLayer, Circle, Popup, Marker } from 'react-leaflet';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography, Container, Box, Card, Grid, TextField, Autocomplete , CardHeader} from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { Icon } from 'leaflet';
import Label from '../components/label';
import Iconify from '../components/iconify';
import CONFIG from "../config";


export default function BigImage() {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [currImage, setImage] = useState(decodeURIComponent(window.location.hash.slice(1)));
  const [fileSize, setFileSize] = useState(0);
  const [favorite, setFavorite] = useState(false);
  const [details, showDetails] = useState(false);
  const [currDetail, setCurrDetail] = useState("");
  const [cameraParams, setCameraParams] = useState([]);
  const [colorSelected, setColorSelected] = useState([]);
  const [position, setPosition] = useState([0,0]);
  const [imDimensions, setImgDimensions] = useState("");
  const [tags, setTags] = useState("");
  const [faces, setFaces] = useState([]);
  const [currIds, setCurrIds] = useState([]);
  const [imCoordinates, setImCoordinates] = useState([]);
  const [options, setOptions] = useState(["Claudia", "Thore"]);
  const [isMobile, setIsMobile] = useState(false);
  
  const textFieldsRefs = useRef([]);

  
  const imageRef = useRef(null);
  const imageRefSmall = useRef(null);
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      setIsMobile(window.innerWidth <= 500);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  async function fetchImageData(url) {
    const token = localStorage.getItem('token');
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      },
    });
    const blob = await response.blob();
    return blob;
  }
  
  useEffect(() => {
    let imageUrl = `${CONFIG.BACKEND_URL}/api/images/${currImage}`
    
    fetchImageData(imageUrl)
    .then(blob => {
      const imageUrl = URL.createObjectURL(blob);
      if (imageRef.current) {
        imageRef.current.src = imageUrl;
      }
    })
    .catch(error => {
      console.error('Error fetching image:', error);
    });
    imageUrl = `${CONFIG.BACKEND_URL}/api/faces/${currImage}`
    
    fetchImageData(imageUrl)
    .then(blob => {
      const imageUrl = URL.createObjectURL(blob);
      if (imageRefSmall.current) {
        imageRefSmall.current.src = imageUrl;
      }
    })
    .catch(error => {
      console.error('Error fetching image:', error);
    });
  }, []);
  useEffect(() => {
    // Map over the faces array and create refs for each TextField
    textFieldsRefs.current = faces.map(() => React.createRef());
  }, [faces]);
  async function fetchData(url) {
    const token = localStorage.getItem('token');
    console.log(token);
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    
    let  data = await response.json();
    try {
      data = JSON.parse(data);    
    } catch (error) {
      console.log("err")
    }
  
    return data;
  }
  useEffect(() => {  
    setIsMobile(window.innerWidth <= 500);
    async function getPersons(){
      let  profiles  = await fetchData(`${CONFIG.BACKEND_URL}/api/get_persons`);
      profiles = profiles.map( (item) => `${item.vorname} ${item.nachname}`) 
      profiles.push("?")
      profiles.push("Thore Koritzius")
      console.log("profiles")
      console.log(profiles)
      setOptions(profiles)
    }getPersons();

  }, []);
  
  const LightSource = {
    "0": "Unknown light source",
    "1": "Daylight (typically around 5500-6500K color temperature)",
    "2": "Fluorescent light",
    "3": "Tungsten (Incandescent) light",
    "4": "Flash",
    "9": "Fine weather conditions",
    "10": "Cloudy weather conditions",
    "11": "Shade",
    "12": "Daylight fluorescent (D 5700-7100K)",
    "13": "Day white fluorescent (N 4600-5400K)",
    "14": "Cool white fluorescent (W 3900-4500K)",
    "15": "White fluorescent (WW 3200-3700K)",
    "17": "Standard light A (2856K, tungsten filament lamp)",
    "18": "Standard light B (4874K, direct sunlight at noon)",
    "19": "Standard light C (6774K, average daylight)",
    "20": "D55 light (5500K, mid-morning or mid-afternoon daylight)",
    "21": "D65 light (6500K, daylight-balanced)",
    "22": "D75 light (7500K, north sky daylight)",
    "23": "D50 light (5000K, horizon light)",
    "24": "ISO studio tungsten",
    "255": "Other light source (reserved for proprietary usage)"
}

const FlashDict = {
  0: "Flash did not fire",
  1: "Flash fired",
  5: "Flash fired but returned no strobe return detection",
  7: "Flash fired and strobe return light was detected",
  9: "Flash fired and return light was not detected (shadow mode)",
  16: "Flash fired, but the mode could not be determined",
  24: "Flash fired, and the mode is unknown",
  25: "Flash fired, and the mode is not auto, but the exact mode could not be determined",
  29: "Flash fired, and the mode is auto, but the light returned was outside of the meter's range"
}
const OrientationDict = {
  1: "Normal (0 degrees)",
  2: "Flipped horizontally",
  3: "Rotated 180 degrees",
  4: "Flipped vertically",
  5: "Rotated 90 degrees, flipped horizontally",
  6: "Rotated 270 degrees",
  7: "Rotated 90 degrees, flipped vertically",
  8: "Rotated 90 degrees"
}
const MeteringMode = {
  0: "Unknown",
  1: "Average",
  2: "Center-weighted average",
  3: "Spot",
  4: "Multi-spot",
  5: "Pattern",
  6: "Partial",
  255: "Other"
}
const SensingMethod = {
  1: "Not defined",
  2: "One-chip color area sensor",
  3: "Two-chip color area sensor",
  4: "Three-chip color area sensor",
  5: "Color sequential area sensor",
  7: "Trilinear sensor",
  8: "Color sequential linear sensor"
}
const ExposureProgram = {
  0: "Not defined",
  1: "Manual",
  2: "Program AE",
  3: "Aperture priority AE",
  4: "Shutter priority AE",
  5: "Creative (Slow speed)",
  6: "Action (High speed)",
  7: "Portrait",
  8: "Landscape",
  9: "Bulb",
}

const ExposureMode = {
  0: "Auto exposure",
  1: "Manual exposure",
  2: "Auto bracket"
}
const SceneCaptureType = {
  0: "Standard",
  1: "Landscape",
  2: "Portrait",
  3: "Night scene"
}

const YCbCrPositioning = {
  1: "Centered",
  2: "Co-sited"
}
const ColorSpace =  {
  1: "sRGB",
  2: "Adobe RGB",
  65535: "Uncalibrated"
}
const WhiteBalance = {
  "0": "Auto",
  "1": "Manual",
  "2": "Auto bracket",
  "3": "Custom",
  "4": "Kelvin",
  "5": "Daylight",
  "6": "Cloudy",
  "7": "Tungsten",
  "8": "Fluorescent",
  "9": "Flash",
  "10": "Shade",
  "11": "Color temperature",
  "12": "PC set1",
  "13": "PC set2",
  "14": "PC set3",
  "15": "PC set4",
  "16": "PC set5",
  "17": "Custom 1",
  "18": "Custom 2",
  "19": "Custom 3",
  "20": "Custom 4",
  "21": "Custom 5",
  "22": "Custom 6",
  "23": "Custom 7",
  "24": "Custom 8",
  "255": "Other"
}



const SensitivityType = {
    "1": "ISO 1 - Extremely Low sensitivity.",
    "50": "ISO 50 - Very Low sensitivity.",
    "100": "ISO 100 - Standard sensitivity.",
    "200": "ISO 200 - Medium sensitivity.",
    "400": "ISO 400 - Medium-high sensitivity.",
    "800": "ISO 800 - High sensitivity.",
    "1600": "ISO 1600 - Very high sensitivity.",
    "3200": "ISO 3200 - Extremely high sensitivity.",
    "6400": "ISO 6400 - Ultra high sensitivity.",
    "12800": "ISO 12800 - Very Ultra high sensitivity.",
    "25600": "ISO 25600 - Extreme sensitivity.",
    "51200": "ISO 51200 - Beyond Extreme sensitivity."
}
const Sharpness = {
    "0": "Sharpness Off - No edge enhancement applied.",
    "1": "Sharpness Low - Minimal edge enhancement.",
    "2": "Sharpness Medium Low - Slightly more edge enhancement.",
    "3": "Sharpness Medium - Moderate edge enhancement.",
    "4": "Sharpness Medium High - Stronger edge enhancement.",
    "5": "Sharpness High - High level of edge enhancement.",
    "6": "Sharpness Very High - Extremely strong edge enhancement."
  }

  const ResolutionUnit = {
    1: "No absolute unit of measurement. Used for pixel aspect ratio.",
    2: "Inch",
    3: "Centimeter"
  };
  

  const fetchSearch = async () => {
    try {
      const responseData = await postData(`${CONFIG.BACKEND_URL}/api/get_image_infos`, { input: `/${currImage}`});
      const res= JSON.parse(responseData);
      console.log(res)
      const ids = res.filter(item => 'id' in item).map((item)=> item.id)
      console.log("ids")
      console.log(ids)
      if (ids !== null && ids !== undefined && ids.length > 0 && ids[0] !== null)
        setCurrIds(ids);
      setImCoordinates(res.filter((name) => 'person' in name && name.person !== null).map((item) => [item.left_coord, item.top_coord, item.right_coord, item.bottom_coord]))
      setFaces(res.filter((name) => 'person' in name && name.person !== null).map((item) => (item.person)))
      setColorSelected(res.filter((name) => 'person' in name && name.person !== null).map((item) => false))
      setFileSize(res[0].size);
      setFavorite(res[0].favorite)
      setTags(res[0].image_tags)
      const last = res[res.length -1]
      if ('ImageWidth' in last || 'Model' in last || 'ExifVersion' in last) {
        console.log(last)
        if ('LightSource' in last)
          last.LightSource = LightSource[last.LightSource];

        if ('Flash' in last)
          last.Flash = FlashDict[last.Flash];

        if ('Orientation' in last)
          last.Orientation = OrientationDict[last.Orientation];

        if ('MeteringMode' in last)
          last.MeteringMode = MeteringMode[last.MeteringMode];

        if ('SensingMethod' in last)
          last.SensingMethod = SensingMethod[last.SensingMethod];

        if ('ExposureProgram' in last)
          last.ExposureProgram = ExposureProgram[last.ExposureProgram];

        if ('ExposureMode' in last)
          last.ExposureMode = ExposureMode[last.ExposureMode];

        if ('SceneCaptureType' in last)
          last.SceneCaptureType = SceneCaptureType[last.SceneCaptureType];

        if ('YCbCrPositioning' in last)
          last.YCbCrPositioning = YCbCrPositioning[last.YCbCrPositioning];

        if ('ColorSpace' in last)
          last.ColorSpace = ColorSpace[last.ColorSpace];

        if ('WhiteBalance' in last)
          last.WhiteBalance = WhiteBalance[last.WhiteBalance];

        if ('SensitivityType' in last)
          last.SensitivityType = SensitivityType[last.SensitivityType];

        if ('Sharpness' in last)
          last.Sharpness = Sharpness[last.Sharpness];
        if ('ResolutionUnit' in last)
          last.ResolutionUnit = ResolutionUnit[last.ResolutionUnit];

        if ("GPSInfo" in last && last.GPSInfo != null && last.GPSInfo.length > 0){
          console.log("last.GPSInfo")
          console.log(last.GPSInfo)
          setPosition(last.GPSInfo.split(', '))
        }

        console.log(last)
        const keyValueArray = Object.entries(last);

        // Sort the array based on keys
        keyValueArray.sort((a, b) => a[0].localeCompare(b[0])); // Use 'localeCompare' for string comparison
        
        // Construct a new object from the sorted array
        const sortedObject = Object.fromEntries(keyValueArray);
        //  last.SubsecTime= (last.SubsecTime)
        setCameraParams(sortedObject)
      }
      return res;
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  };
  useEffect(() => {
      fetchSearch()
  }, []);
  const imgStyle = {
    height: `${windowHeight}px`, 
    display: 'block',
    margin: '0 auto',
    objectFit: 'contain',
  };
  const imgStyle2 = {
    height: `${windowHeight / 2}px`, 
    display: 'block',
    margin: '0 auto',
    objectFit: 'contain',
  };
  const cardB = {
    minHeight: `${windowHeight / 2}px`, 
    display: 'block',
    margin: '0 auto',
    background: '#111'
  };
  const saveBtn = {
    display: 'block',
    margin: '0 auto',
    top: '10px',
    textAlign: 'center'
  };
  const darkBackground = {
    backgroundColor: '#404040', // Change this to your desired dark background color
    color: 'white', // Change this to your desired text color
  };
    
  function formatFileSize(sizeInBytes) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];

    if (sizeInBytes === 0) return '0 B';

    const i = parseInt(Math.floor(Math.log(sizeInBytes) / Math.log(1024)), 10);
    const size = (sizeInBytes / (1024 ** i)).toFixed(2);

    return `${size} ${units[i]}`;
  }

  const changeFavorite = async() => {
    console.log((!favorite).toString())
    const responseData = await postData(`${CONFIG.BACKEND_URL}/api/set_favorite`, { input: (!favorite).toString(), path: currImage});

    setFavorite(!favorite);
  };
  const changeDetails = () => {
    showDetails(!details);
  };
  const handleImageLoad = () => {
    const imageElement = imageRef.current;
          const width = imageElement.naturalWidth;
          const height = imageElement.naturalHeight;
          setImgDimensions(`${width} x ${height}`);
  }

  async function postData(url, payload) {
    const token = localStorage.getItem('token');
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    return data;
  }
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  };

  const exifTags = {
    'ImageWidth': "The width of the image in pixels.",
    'ImageLength': "The height of the image in pixels.",
    'ResolutionUnit': "The unit of measurement for XResolution and YResolution.",
    'ExifOffset': "The offset to the start of EXIF data.",
    'Make': "The camera manufacturer.",
    'Model': "The camera model number or name.",
    'Orientation': "The orientation of the image. 1 represents normal orientation.",
    'YCbCrPositioning': "The positioning of subsampled chrominance components relative to luminance samples. In digital imaging, the YCbCr color space is commonly used to represent colors, where Y represents the luma (brightness) component, and Cb and Cr represent the chroma (color) components. \n\n YCbCrPositioning = 1: Centered In this case, the chrominance samples (Cb and Cr) are aligned with the center of the corresponding Y (luminance) samples. This is a common positioning for digital cameras. \n \n YCbCrPositioning = 2: Co-sited In this case, the chrominance samples are co-sited or located adjacent to the corresponding Y samples. This positioning is often used in other digital imaging systems.",
    'DateTime': "The date and time of image creation.",
    'XResolution': "The number of pixels per ResolutionUnit in the image width direction.",
    'YResolution': "The number of pixels per ResolutionUnit in the image height direction.",
    'ExifVersion': "The version of the EXIF standard.",
    'ApertureValue': "Aperture is the opening in a camera lens that controls the amount of light entering the camera. It is represented by an f-stop value (e.g., f/2.8, f/5.6), where a lower f-stop number means a larger aperture, allowing more light to reach the camera sensor. A larger aperture (lower f-stop) results in a shallow depth of field, blurring the background and making the subject stand out. ",
    'ColorSpace': "The color space of the image data. Value 1 represents sRGB color space.",
    'ExposureBiasValue': "The exposure bias (compensation) value of the image in EV.",
    'MaxApertureValue': "The maximum aperture value of the lens.",
    'ExifImageHeight': "The height of the image as stored in the EXIF data.",
    'BrightnessValue': "The brightness value of the image in EV.",
    'DateTimeOriginal': "The date and time of the original image creation.",
    'WhiteBalance': "In cameras, white balance is a setting that adjusts the color temperature of an image to compensate for the color cast caused by the lighting conditions. \n\n The white balance mode set when the image was shot. Value 0 represents auto white balance.",
    'ExifInteroperabilityOffset': "The offset to the start of Exif interoperability IFD.",
    'Flash': "Indicates the status of the flash when the image was shot. ",
    'GPSInfo': "Latitude, Longtitude where the image was shot.",
    'Saturation': "Saturation refers to the intensity or purity of colors present in an image. It is one of the three components of the HSL (Hue, Saturation, and Lightness) color model and is also related to the HSV (Hue, Saturation, and Value) color model. \n\n Saturation determines how vivid and vibrant the colors appear in an image. A fully saturated image has very intense and pure colors, while a desaturated image appears more grayscale or muted. The saturation value is usually represented as a percentage, where 0% means fully desaturated (grayscale), and 100% represents fully saturated colors.",
    'Rating': "The Rating tag is used to indicate the subjective rating or score assigned to an image by the photographer or the user. It allows users to mark their images with a rating, usually on a scale of 0 to 5 stars, to express their preference or the perceived quality of the photo. The purpose of this tag is to help users easily filter and organize their images based on their quality or personal preference. ",
    'ExifImageWidth': "The width of the image as stored in the EXIF data.",
    'MeteringMode': "MeteringMode in photography refers to the method or mode the camera uses to determine the exposure settings, particularly the combination of aperture, shutter speed, and ISO sensitivity, for capturing an image. The camera's metering system measures the intensity of light in the scene and calculates the appropriate exposure values to achieve a balanced and well-exposed photograph.\n \n \n Average: The camera calculates exposure based on the average light intensity across the entire scene.\n \n Center-weighted average: The camera gives more importance to the central part of the frame while measuring exposure.\n \n Spot: The camera meters the exposure based on a small and specific spot in the scene, typically the center.\n \n Multi-spot: The camera takes multiple spot meter readings and calculates exposure based on an algorithm.\n \n Pattern (Matrix/Evaluative): The camera uses a sophisticated algorithm to evaluate different areas of the scene and determine the best exposure.\n \n Partial: Similar to spot metering but covering a larger area, often around 10-15% of the frame.",
    'SubsecTime': "A tag used to record fractions of seconds for DateTime.",
    'SubsecTimeOriginal': "A tag used to record fractions of seconds for DateTimeOriginal.",
    'SubsecTimeDigitized': "A tag used to record fractions of seconds for DateTimeDigitized.",
    'FocalLength': "The focal length of the lens in millimeters. \n\n Focal length is the distance between the camera's lens and the image sensor when the lens is focused at infinity. It determines the field of view and magnification of the captured image. Smaller focal lengths result in a wider field of view (wide-angle), while larger focal lengths lead to a narrower field of view (telephoto).",
    'DateTimeDigitized': "The date and time when the image was digitized.",
    'ShutterSpeedValue': "The shutter speed value of the image in seconds.",
    'SensingMethod': "The image sensor type on the camera or image input device.",
    'ExposureTime': "The exposure time in seconds.",
    'FNumber': "The f-number (aperture) setting when the image was taken. Also known as the aperture value or f-stop, is a crucial setting in photography that determines the size of the lens aperture. It represents the ratio of the lens's focal length to the diameter of the aperture. A smaller FNumber indicates a larger aperture, allowing more light to enter the camera sensor, which results in a shallower depth of field and brighter images. Conversely, a larger FNumber indicates a smaller aperture, reducing the amount of light and resulting in a deeper depth of field and darker images.",
    'ExposureProgram': "Exposure Program in photography refers to the mode or method the camera uses to determine the combination of aperture, shutter speed, and ISO sensitivity for capturing an image. It allows photographers to control the exposure settings manually or use automatic modes provided by the camera. The common exposure program modes include Manual (M), Program Auto (P), Aperture Priority (A or Av), and Shutter Priority (S or Tv). Each mode offers different levels of control over the exposure, giving photographers the flexibility to achieve their desired creative effects.",
    'ISOSpeedRatings': "The ISO speed rating of the camera when the image was taken.",
    'ExposureMode': "The exposure mode set when the image was shot. Value 0 represents auto exposure.",
    'LightSource': "The kind of light source used for the shot.",
    'FocalLengthIn35mmFilm': "The focal length equivalent for a 35mm film camera.",
    'SceneCaptureType': "The type of scene that was captured.",
  }
  const [selectedTag, setSelectedTag] = useState(null);
  const handleTagClick = (tag) => {
    console.log("tags")
    console.log(cameraParams   )
    console.log(cameraParams[tag])
    setCurrDetail(cameraParams[tag])
    setSelectedTag(tag);
  };

  const handleClose = () => {
    setSelectedTag(null);
  };
  const handleKeyDown = (event, tag) => {
    if (event.keyCode === 13) {
      // Enter key pressed
      handleTagClick(tag);
    }
  };
  const handleChange = (index, newValue) => {
    const updatedFaces = [...faces];
    updatedFaces[index] = newValue;
    setFaces(updatedFaces);
  };
  
  const  savePersons = async() => {
    const responseData = await postData(`${CONFIG.BACKEND_URL}/api/update_face_data`, { input: faces, path: currImage, ids: currIds});
    fetchSearch();
  };
  const getImageCoordinates = (event) => {
    const imageElement = imageRefSmall.current;
    if (imageElement) {
      const imageRect = imageElement.getBoundingClientRect();
      const imageWidth = imageElement.naturalWidth;
      const imageHeight = imageElement.naturalHeight;
      console.log(imageWidth);
      const clientX = event.clientX;
      const clientY = event.clientY;

      // Calculate DPI-adjusted mouse coordinates
      const dpiAdjustedX = (clientX - imageRect.left) * (imageWidth / imageRect.width);
      const dpiAdjustedY = (clientY - imageRect.top) * (imageHeight / imageRect.height);

      return [dpiAdjustedX, dpiAdjustedY];
    }
    return [0, 0];
  };
  const [scrollToIndex, setScrollToIndex] = useState(-1);

  useEffect(() => {
    if (scrollToIndex !== -1 && textFieldsRefs.current[scrollToIndex] && textFieldsRefs.current[scrollToIndex].current) {
      textFieldsRefs.current[scrollToIndex].current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
      setScrollToIndex(-1); // Reset the scrollToIndex to prevent continuous scrolling
    }
  }, [scrollToIndex]);

  const addNewFace = () => {
    if (currIds === null || currIds.length ===0) {
      setCurrIds([0]);
    } else {
      setCurrIds([...currIds, currIds.length > 0 ? ((Math.max(...currIds) * (-1)) + 1) : 0]);
    }    
    if (faces === null || faces.length ===0) {
      setFaces(['']); 
    } else {      
      setFaces([...faces, '']); 
    }    
    
   
  };
  useEffect(() => {
   console.log(faces)
  }, [faces]);
  useEffect(() => {
   console.log(currIds)
  }, [currIds]);

  const handleInteraction = (event) => {
    if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
      const [x, y] = getImageCoordinates(event);
      console.log(`Clicked coordinates (Image): x: ${x}, y: ${y}`);
      const clickedRectangleIndex = imCoordinates.findIndex(
        (rect) => x >= rect[0] && x <= rect[2] && y >= rect[1] && y <= rect[3]
      );
  
      if (clickedRectangleIndex !== -1) {
        const newColorSelected = Array.from({ length: imCoordinates.length }, (_, index) => index === clickedRectangleIndex);
        setColorSelected(newColorSelected);
        setScrollToIndex(clickedRectangleIndex);
        //  setFaces(newColorSelected.map((item) => "test"))
  
        console.log(`Clicked inside Rectangle ${clickedRectangleIndex}`);
      } else {
        const newColorSelected = Array.from({ length: imCoordinates.length });
        setColorSelected(newColorSelected);
        console.log('Clicked outside all rectangles.');
      }
    }
  };
  const filterOptions = (options, { inputValue }) => {
    const filteredOptions = options.filter((option) =>
      option.substr(0, inputValue.length).toUpperCase() === inputValue.toUpperCase()
    );
    
    const sortedOptions = filteredOptions.sort((a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0; 
    });
    return sortedOptions.slice(0, 3);
  };
  const mapboxAccessToken = "pk.eyJ1IjoibGF3aXgxMCIsImEiOiJjamJlOGE1bmcyZ2V5MzNtcmlyaWRzcDZlIn0.ZRQ73zzVxwcADIPvsqB6mg"

  return (
    <>
      <Helmet>
        <title> {currImage} </title>
      </Helmet>

      <Container>
        <img ref={imageRef}   alt={""}  onLoad={handleImageLoad} style={imgStyle}/>
        
        {position[0] !== 0 && position[1] !== 0 && (<Grid container spacing={2} style={containerStyle}> 
        <Grid item xs={12} md={6} lg={6}>
        <Card style={{ height: '400px', minHeight: '100%' ,background: '#000'}}  >
          
        <MapContainer center={position} zoom={12} style={{ height: '100%', width: '100%',background: '#000' }}>
          <TileLayer  url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${mapboxAccessToken}`} style={{ height: '100%', width: '100%',background: '#000' }}/>
          <Circle center={position} radius={100} pathOptions={{color: '#f00'}}>
          <Iconify icon={'mdi:star'} color='#999' />
        </Circle>
          
        </MapContainer>
    </Card>
          </Grid>
          </Grid>)}
          <Grid container spacing={2} style={containerStyle}> 

        <Grid item xs={12} md={6} lg={6}>
            <Card style={{  background: '#111', paddingBottom: '20px'}}>
              <CardHeader title={
              <a href={`/dashboard/day#${currImage.split('/')[0]}`}>{currImage.split('/')[0]}</a>} />
              

              <table style={{ width: '100%', padding: '25px', marginLeft: '20px', marginRight: '20px', marginTop: '5px'}}>
                <tbody>
                  
                    <tr>
                      <td style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>Name</td>
                      <td
                          style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>{currImage.split('/')[1]}</td>
                    </tr>
                    <tr>
                      <td style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>Size</td>
                      <td
                          style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>{formatFileSize(fileSize)}</td>
                    </tr>
                    <tr>
                      <td style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>Dimensions</td>
                      <td
                          style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>{imDimensions}</td>
                    </tr>
                    <tr>
                      <td style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>Favorit</td>
                      
                      <td
                          style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>
                              <div
                                  role="button" // Add role="button" to indicate this is interactive
                                  tabIndex="0" // Add tabIndex="0" to make it focusable
                                  style={{ cursor: 'pointer' }}
                                  onClick={changeFavorite}
                                  onKeyDown={(event) => handleKeyDown(event, 0)}
                                >
                              <Label color={favorite ? 'success' : 'error'}>{favorite ? 'Yes' : 'No'}</Label>
                            </div>
                          </td>
                    </tr>
                    <tr>
                      <td style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>Tags</td>
                      <td
                          style={{  padding: '10px',textAlign: 'left', border: '1px solid #000' }}>{tags}</td>
                    </tr>
                </tbody>

                {details && (Object.keys(cameraParams).map((key, index) => (
                  <tr key={index}>
                  <td style={{  padding: '10px', border: '1px solid #000' }}>
                    <div
                      role="button" // Add role="button" to indicate this is interactive
                      tabIndex="0" // Add tabIndex="0" to make it focusable
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleTagClick(key)}
                      onKeyDown={(event) => handleKeyDown(event, key)}
                    >
                      {isMobile ? key.substring(0,13) : key}
                    </div>
                  </td>
                  <td style={{ padding: '10px', border: '1px solid #000' }}>
                    {cameraParams[key] !== undefined && cameraParams[key] !== null ? cameraParams[key].toString() : ''}
                  </td>
                </tr>
                )))}

              </table>
              {('ImageWidth' in cameraParams || 'Model' in cameraParams || 'ExifVersion' in cameraParams) && (
                <>
              <Button size="large" variant="contained" style={saveBtn} color={"secondary"} onClick={changeDetails}>
                        Show Details
                    </Button><div style={{height: '20px'}}/></>)}

            </Card>
        </Grid>   
        </Grid>   
        {(<Grid container spacing={2} style={{marginTop: '5px'}}>    
            <Grid item xs={12} md={6} lg={6} >
            <Card style={{background: '#111'}}>
              
            <button               
                onClick={handleInteraction}
                onKeyDown={handleInteraction}
                style={{ background: 'none', border: 'none', padding: 0 }}
              >
            <img   ref={imageRefSmall}  alt={currImage}  style={imgStyle2}/></button>
            </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
            <Card style={cardB}>
                <CardHeader title={"Faces"} style={{paddingBottom: '10px'}}/>
                {faces.map((name, index) => (                                     
                    <Autocomplete
                    options={options}
                    filterOptions={filterOptions}
                    value={name}
                    PaperComponent={({ children }) => (
                        <div style={{ ...darkBackground, maxHeight: '200px', overflowY: 'auto' }}>
                        {children}
                        </div>
                    )}
                    renderInput={(params) => (
                        <TextField
                        ref={textFieldsRefs.current[index]}
                        {...params}
                        value={name}
                        InputProps={{
                            ...params.InputProps,
                            style: {
                            color: darkBackground.color,
                            background: colorSelected[index] ? '#218f22' : '#111',
                          },
                        }}
                        inputProps={{
                            ...params.inputProps,
                            style: {
                            color: darkBackground.color,
                            background: colorSelected[index] ? '#218f22' : '#111',
                            },
                        }}
                        />
                    )}
                    onInputChange={(event, newValue) => handleChange(index, newValue)}

                    />
                ))}
              <div style={{marginTop: '10px'}}>
              <Button
                variant="contained"
                
                style={{ width: '50%',  height: '50px', background: '#303030' }}
                onClick={addNewFace}>
                + Person
              </Button>
                    <Button size="large" variant="contained" color={"secondary"} style={{ width: '50%',  height: '50px' }} onClick={savePersons}>
                        Save
                    </Button><div style={{height: '25px'}}/>
                    </div>
            </Card>
            </Grid>
        </Grid>)}
        <div style={{height: '50px'}}/>
      </Container>

      <Dialog open={selectedTag !== null} onClose={handleClose}>
      <div style={{ minWidth:isMobile? undefined : 500, background: '#111'}}>
        {selectedTag !== null && (
          <>
            <DialogTitle>
            {selectedTag}: {currDetail.toString()}
              </DialogTitle>            
              <DialogContent>
              <DialogContentText>
  {exifTags[selectedTag] && (
    <span
      dangerouslySetInnerHTML={{
        __html: exifTags[selectedTag].toString().replace(/\n/g, '<br/>'),
      }}
    />
  )}
</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </>
        )}
        </div>
      </Dialog>
    </>
  );
}
